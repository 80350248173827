<template>
    <div>
        <loading-screen v-if="$store.state.loadingScreen"></loading-screen>
        <el-row style="display: flex; align-items: center;">
            <el-col :span="22" class="component-title" v-if="!isMobile()">
                User Information
            </el-col>
            <el-col v-else>
                &nbsp;
            </el-col>
            <el-col :span="2" v-if="!isMobile()">
                <profile-avatar></profile-avatar>
            </el-col>
        </el-row>
        <el-tabs type="card" class="user-info-tabs">
            <el-tab-pane label="Login">
                <el-row>
                    <data-tables :page-size="5" :filters="filters"
                                 :pagination-props="{ pageSizes: [5, 10, 20, 50, 100] }" :data="getUserTableData"
                                 style="height: 80vh; overflow-y: auto">
                        <el-row slot="tool" style="margin: 10px 0">
                            <el-col :xl="5" :lg="5" :md="8" :sm="24" :xs="24">
                                <el-input placeholder="Search name/email" v-model="filters[0].value"
                                          clearable></el-input>
                            </el-col>
                        </el-row>
                        <el-table-column fixed label="Name" :prop="'name'" width="200"
                                         :sortable="true"></el-table-column>
                        <el-table-column label="Email" :prop="'email'" width="260" :sortable="true"></el-table-column>
                        <!--                        <el-table-column label="User Type" :prop="'user_meta.user_type'" width="120"-->
                        <!--                                         :sortable="true"></el-table-column>-->
                        <el-table-column label="School" :prop="'user_meta.school'" width="300"
                                         :sortable="true"></el-table-column>
                        <el-table-column label="College" :prop="'user_meta.college'" width="300"
                                         :sortable="true"></el-table-column>
                        <el-table-column :formatter="cellValueRenderer" label="Recommendations Generated"
                                         :prop="'user_meta.recommendations_generated'" width="200"
                                         :sortable="true"></el-table-column>
                        <el-table-column label="Date Joined" :prop="'joined'" width="200"
                                         :sortable="true"></el-table-column>
                        <el-table-column label="Last Login" :prop="'login'" width="200"
                                         :sortable="true"></el-table-column>
                        <el-table-column label="Coupon code used" :prop="'user_meta.coupon'" width="200"
                                         :sortable="true"></el-table-column>
                    </data-tables>
                </el-row>
            </el-tab-pane>
            <el-tab-pane label="Queries" v-if="false">
                <el-row>
                    <data-tables :data="getUserQueryTableData" :page-size="5"
                                 :pagination-props="{ pageSizes: [5, 10, 20, 50, 100] }"
                                 style="height: 80vh; overflow-y: auto">
                        <el-table-column fixed label="id" :prop="'id'" width="100" :sortable="true"></el-table-column>
                        <el-table-column fixed label="Name" :prop="'name'" width="auto"
                                         :sortable="true"></el-table-column>
                        <el-table-column label="Email" :prop="'email'" width="auto" :sortable="true"></el-table-column>
                        <el-table-column label="Query" :prop="'query'" width="auto"
                                         :sortable="true"></el-table-column>
                        <el-table-column label="Date" :prop="'created'" width="auto"
                                         :sortable="true"></el-table-column>
                    </data-tables>
                </el-row>
            </el-tab-pane>
            <el-tab-pane label="GC Registration requests">
                <el-row>
                    <data-tables :data="getGCRegistrationRequests" :page-size="5" :filters="guidanceCounsellorFilter"
                                 :pagination-props="{ pageSizes: [5, 10, 20, 50, 100] }"
                                 style="height: 80vh; overflow-y: auto">
                        <el-row slot="tool" style="margin: 10px 0">
                            <el-col :xl="5" :lg="5" :md="8" :sm="24" :xs="24">
                                <el-input placeholder="Search name/email/school"
                                          v-model="guidanceCounsellorFilter[0].value" clearable></el-input>
                            </el-col>
                        </el-row>
                        <el-table-column fixed label="Name" :prop="'name'" width="100"
                                         :sortable="true"></el-table-column>
                        <el-table-column fixed label="Email" :prop="'email'" width="250"
                                         :sortable="true"></el-table-column>
                        <el-table-column label="Phone" :prop="'phone'" width="250" :sortable="true"></el-table-column>
                        <el-table-column label="School" :prop="'school'" width="250"
                                         :sortable="true"></el-table-column>
                        <el-table-column label="Date" :prop="'created'" width="250"
                                         :sortable="true"></el-table-column>
                        <el-table-column label="School Roll Number" :prop="'school_roll_number'" width="250"
                                         :sortable="true"></el-table-column>
                        <el-table-column label="Intended Use" :prop="'intended_use'" width="250"
                                         :sortable="true"></el-table-column>
                        <el-table-column label="Active" :prop="'active'" width="250"
                                         :formatter="cellActiveValueRenderer"
                                         :sortable="true"></el-table-column>
                        <el-table-column
                                fixed="right"
                                label="Operation"
                                width="120">
                            <template slot-scope="scope">
                                <el-button v-if="cellActiveValueRenderer(scope.row) === 'No'"
                                           @click.native.prevent="editRow(scope.$index, scope.row)">
                                    Activate GC
                                </el-button>
                                <el-button v-else disabled>
                                    Activated
                                </el-button>
                            </template>
                        </el-table-column>
                    </data-tables>
                </el-row>
            </el-tab-pane>
            <el-tab-pane label="Pre-Bookings">
                <el-row>
                    <data-tables :data="getUserBookingsData" :page-size="5"
                                 :pagination-props="{ pageSizes: [5, 10, 20, 50, 100] }"
                                 style="height: 80vh; overflow-y: auto">
                        <el-table-column fixed label="Name" :prop="'name'" width="auto"
                                         :sortable="true"></el-table-column>
                        <el-table-column label="Email" :prop="'email'" width="auto" :sortable="true"></el-table-column>
                        <el-table-column label="Date" :prop="'created'" width="auto"
                                         :sortable="true"></el-table-column>
                    </data-tables>
                </el-row>
            </el-tab-pane>
        </el-tabs>

    </div>
</template>

<script>
  import ProfileAvatar from '../../main_components/ProfileAvatar';
  import LoadingScreen from '../../main_components/LoadingScreen';

  export default {
    name: 'AdminPanel',
    components: {LoadingScreen, ProfileAvatar},
    mounted() {
      let self = this;
      self.checkUserLoggedIn().then(response => {
        if (!self.$store.state.isAdmin) {
          self.$router.push('/');
        }
        self.$store.state.loadingScreen = true;
        this.getUserData().then(response => {
          this.getGCRegistered().then(response => {
            this.getUserPreBookings().then(response => {
              self.$store.state.loadingScreen = false;
            }).catch(error => {
              self.$store.state.loadingScreen = false;
            });
          }).catch(error => {
            self.$store.state.loadingScreen = false;
          });
        }).catch(error => {
          self.$store.state.loadingScreen = false;
        });
      }).catch(error => {
        self.$store.state.loadingScreen = false;
      });

      // this.getUserQueries();
    },
    computed: {
      getUserTableData: function() {
        return this.usersData;
      },
      getUserBookingsData: function() {
        return this.userPrebookings;
      },
      getUserQueryTableData: function() {
        return this.userQueries;
      },
      getGCRegistrationRequests: function() {
        return this.registeredGC;
      },
    },
    data: function() {
      return {
        filters: [
          {
            value: '',
            prop: ['name', 'email'],
          }],
        guidanceCounsellorFilter: [
          {
            value: '',
            prop: ['name', 'email', 'school'],
          }],
        columns: [
          {
            label: 'Name',
            prop: 'name',
          }, {
            label: 'Email',
            prop: 'email',
          }, {
            label: 'Date Joined',
            prop: 'joined',
          }, {
            label: 'Last Login',
            prop: 'login',
          }, {
            label: 'user_meta.user_type',
            prop: 'User Type',
          }, {
            label: 'user_meta.school',
            prop: 'School',
          }, {
            label: 'user_meta.college',
            prop: 'College',
          }, {
            label: 'user_meta.recommendations_generated',
            prop: 'Recommendations Generated',
          }, {
            label: 'user_meta.coupon',
            prop: 'Coupon code used',
          }],
        usersData: [],
        userQueries: [],
        userPrebookings: [],
        registeredGC: [],
        loadingScreen: false,
      };
    },
    methods: {
      cellActiveValueRenderer: function(row) {
        if (row.active)
          return 'Yes';
        return 'No';
      },
      editRow: function(index, row) {
        let self = this;
        let url = `${self.getBaseUrl()}/accounts/change-status-gc/`;
        let email = row.email;
        self.$store.state.loadingScreen = true;
        $.ajax({
          url: url,
          type: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({email: email}),
          beforeSend: function(request) {
            if (self.getAuthToken()) {
              request.setRequestHeader(
                  'Authorization',
                  'Token ' + self.getAuthToken(),
              );
            }
          },
          success: function(response) {
            self.$message({
              title: 'Successful',
              message: 'Course data successfully updated',
              type: 'success',
              duration: 3000,
              showClose: true,
            });
            self.getGCRegistered().then(response => {
              self.$store.state.loadingScreen = false;
            }).catch(error => {
              self.$store.state.loadingScreen = false;
            });
          },
          error: function(error) {
            self.$store.state.loadingScreen = false;
            self.$message({
              title: 'Unsuccessful',
              message: 'Update failed',
              type: 'error',
              duration: 3000,
              showClose: true,
            });
          },
        });
      },
      cellValueRenderer: function(row) {
        return row.user_meta.recommendations_generated.toString();
      },
      getUserData: function() {
        return new Promise((resolve, reject) => {
          let self = this;
          let url = self.getBaseUrl() + '/accounts/user_logins/';
          $.ajax({
            url: url,
            type: 'GET',
            beforeSend: function(request) {
              request.setRequestHeader('Authorization',
                  'Token ' + self.getAuthToken());
            },
            success: function(response) {
              self.usersData = response;
              resolve(true);
            },
            error: function(error) {
              reject(error);
            },
          });
        });
      },

      getUserQueries: function() {
        let self = this;
        self.$store.state.loadingScreen = true;
        let url = self.getBaseUrl() + '/accounts/list_user_query/';
        $.ajax({
          url: url,
          type: 'GET',
          beforeSend: function(request) {
            request.setRequestHeader('Authorization',
                'Token ' + self.getAuthToken());
          },
          success: function(response) {
            self.userQueries = response;
            self.$store.state.loadingScreen = false;
          },
          error: function(error) {
            error;
          },
        });
      },
      getGCRegistered: function() {
        return new Promise((resolve, reject) => {
          let self = this;
          let url = self.getBaseUrl() + '/accounts/list-gc/';
          $.ajax({
            url: url,
            type: 'GET',
            beforeSend: function(request) {
              request.setRequestHeader('Authorization',
                  'Token ' + self.getAuthToken());
            },
            success: function(response) {
              self.registeredGC = response;
              resolve(response);
            },
            error: function(error) {
              reject(error);
            },
          });
        });
      },
      getUserPreBookings: function() {
        return new Promise(((resolve, reject) => {
          let self = this;
          let url = self.getBaseUrl() + '/accounts/list_prebookings/';
          $.ajax({
            url: url,
            type: 'GET',
            beforeSend: function(request) {
              request.setRequestHeader('Authorization',
                  'Token ' + self.getAuthToken());
            },
            success: function(response) {
              self.userPrebookings = response;
              resolve(true);
            },
            error: function(error) {
              reject(error);
            },
          });
        }));
      },
    },
  };
</script>

<style>
    .el-table tr td:first-child {
        text-align: center !important;
    }

    .el-tabs__item::after {
        content: none;
    }

    .el-tabs__item {
        width: 200px;
    }

    .el-tabs__item {
        width: 100%;
    }

    .user-info-tabs .el-tab-pane .el-row {
        padding: 16px;
    }
</style>
